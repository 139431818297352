import * as React from "react"
import Layout from "../components/layout"
import Article from "../components/article"
import Hello from "../images/hello.jpg"

const IndexPage = () => {
  return (
    <Layout headerText="index page">
      <Article header="Hello." shortDescription="world?" image={Hello}>
      </Article>
    </Layout>
  )
}

export default IndexPage